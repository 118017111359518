<div class="layout-wrapper" fxLayout="column">
  <mat-toolbar class="app-toolbar mat-primary mat-elevation-z6" role="navigation">
    <mat-toolbar-row>
      <img src="assets/img/fcg-logo.png" class="app-logo" alt="FC Gossau Logo">
      <a routerLink="/" fxFlex class="app-title">FC Gossau - Aufgebote</a>

      <nav fxLayout="row" fxHide fxShow.gt-sm>
        <a class="menu-link" [class.has-icon-min-width]="link.content" *ngFor="let link of links"
          fxFlex mat-button [routerLink]="link.url"
          routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon>{{link.matIcon}}</mat-icon>&nbsp;<span>{{link.content}}</span>
        </a>
      </nav>
    </mat-toolbar-row>

    <mat-toolbar-row fxHide.gt-sm>
      <nav fxLayout="row" class="nav-flex-div">
        <a class="menu-link" *ngFor="let link of links"
        [fxFlex]="link.content ? 'grow' : 'none'" mat-button [routerLink]="link.url"
        routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon [fxHide.xs]="link.content">{{link.matIcon}}</mat-icon>&nbsp;<span>{{link.content}}</span>
        </a>
      </nav>
    </mat-toolbar-row>
  </mat-toolbar>

  <div role="main" ngClass.gt-xs="content-wrapper" ngClass.xs="content-wrapper-xs" fxFlex>
    <ng-content></ng-content>
  </div>
</div>