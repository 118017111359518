import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { AddTeamDialogComponent } from './add-team-dialog/add-team-dialog.component';
import { environment } from '../../environments/environment';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CallUpDisplayComponent } from './call-up-display/call-up-display.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatCardModule,
  ],
  declarations: [
    LoadingDialogComponent,
    AddTeamDialogComponent,
    ConfirmDialogComponent,
    CallUpDisplayComponent
  ],
  entryComponents: [
    LoadingDialogComponent,
    AddTeamDialogComponent,
    ConfirmDialogComponent
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    CallUpDisplayComponent
  ]
})
export class SharedModule { }
