export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyABxUNDre8bfoEseH1vagMHIN07D9RjgVA',
    authDomain: 'fc-gossau.firebaseapp.com',
    databaseURL: 'https://fc-gossau.firebaseio.com',
    projectId: 'fc-gossau',
    storageBucket: 'fc-gossau.appspot.com',
    messagingSenderId: '889758706914'
  }
};
