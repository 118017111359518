<h2 mat-dialog-title>Lade Daten…</h2>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxFlexAlign="center">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
    <div>
      Bitte warten Sie, während wir alle Daten zusammensuchen
    </div>
  </div>
</mat-dialog-content>
