import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Link } from './shared/main-layout/link.model';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'fcg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(authService: AuthService) {
    this.links = authService.isCurrentUserVerifiedCoach.pipe(map(isVerified =>
      isVerified
        ? [
            {
              matIcon: 'assignment',
              content: 'Teams',
              url: ['/Teams']
            },
            {
              matIcon: 'group_add',
              content: null,
              url: ['/Trainer/TrainerVerwalten']
            },
            {
              matIcon: 'security',
              content: null,
              url: ['/Trainer/Login']
            }
          ]
        : [
            {
              matIcon: 'assignment',
              content: 'Teams',
              url: ['/Teams']
            },
            {
              matIcon: 'security',
              content: null,
              url: ['/Trainer/Login']
            }
        ]
    ),
    startWith([
      {
        matIcon: 'assignment',
        content: 'Teams',
        url: ['/Teams']
      }
    ]));
  }

  public links: Observable<readonly Link[]>;
}
