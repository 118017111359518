import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Link } from './link.model';

@Component({
    selector: 'fcg-layout',
    templateUrl: 'main-layout.component.html',
    styleUrls: ['main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent {
    @Input()
    public links: readonly Link[] = [];
}
