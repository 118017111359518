import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../shared/loading-dialog/loading-dialog.component';

/**
 * Displays a global loading dialog while at least one workers is present
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {
  private currentlyOpenedDialog?: MatDialogRef<LoadingDialogComponent>;

  private runningWorkersSource = new Subject<number>();

  private _runningWorkers = 0;

  constructor(private dialog: MatDialog) {
    this.runningWorkersSource
      .pipe(
        map(value => value > 0),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(
        (open) => {
          if (open) {
            this.currentlyOpenedDialog = this.dialog.open(LoadingDialogComponent, { disableClose: true, closeOnNavigation: false });
          } else if (this.currentlyOpenedDialog) {
            this.currentlyOpenedDialog.close();
          }
        }
    );
  }

  public addWorker() {
    this.runningWorkersSource.next(++this._runningWorkers);
  }

  public removeWorker() {
    this.runningWorkersSource.next(--this._runningWorkers);
  }
}
