import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map, switchMap } from 'rxjs/operators';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Collections } from '../shared/constants/collections';
import { Coach } from '../shared/model/coach';
import { LoadingDialogService } from './loading-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private loadingDialog: LoadingDialogService) {
    // this.currentUser.next(null);
    // this.isCurrentUserVerifiedCoach.next(false);

    afAuth.user.subscribe(user => {
      this.ensureUserAddedToDatabase(user);
      this.currentUser.next(user);
    });

    this.currentUser
      .pipe(
        switchMap(u => this.isVerifiedCoach(u))
      )
      .subscribe(verifiedCoach => this.isCurrentUserVerifiedCoach.next(verifiedCoach));

    this.checkForRedirectResult();
  }

  public currentUser = new ReplaySubject<firebase.User | null>(1);
  public isCurrentUserVerifiedCoach = new ReplaySubject<boolean>(1);

  public async login() {
    await this.afAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
  }

  public async logout() {
    await this.afAuth.signOut();
  }

  private async checkForRedirectResult() {
    this.loadingDialog.addWorker();
    try {
      await this.afAuth.getRedirectResult();
    }
    finally {
      this.loadingDialog.removeWorker();
    }
  }

  private isVerifiedCoach(user: firebase.User | null): Observable<boolean> {
    if (user === null) {
      return of(false);
    }

    return this.afs.doc<Coach>(`${Collections.coaches}/${user.uid}`).valueChanges().pipe(map(c => c == null ? false : c.verified));
  }

  private async ensureUserAddedToDatabase(user: firebase.User | null) {
    if (user === null) {
      return;
    }

    const coachDocument = this.afs.doc<Coach>(`${Collections.coaches}/${user.uid}`);

    const documentValue = await coachDocument.get().toPromise();
    if (documentValue.exists) {
      if (documentValue.get('name') !== user.displayName) {
        // Ensure data in the database and in google are kept in sync
        await coachDocument.update({
          name: user.displayName || ''
        });
      }
      return;
    }

    await coachDocument.set({
      email: user.email || '',
      name: user.displayName || '',
      verified: false
    });
  }
}
